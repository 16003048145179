<template>
  <div id="app" class="pxUnit">
    <CpLanding v-if="showLanding == 'cp'"></CpLanding>
    <YbLanding v-if="showLanding == 'yb'"></YbLanding>
    <LaLanding v-if="showLanding == 'la01'"></LaLanding>
    <PJLading v-if="showLanding == 'pj01'"></PJLading>
    <ConciseLading v-if="showLanding == 'concise'"></ConciseLading>
    <WnLanding v-if="showLanding == 'wn'"></WnLanding>
    <ObLanding v-if="showLanding == 'ob'"></ObLanding>
    <KYLanding v-if="showLanding == 'ky'"></KYLanding>
    <YcLanding v-if="showLanding == 'yc'"></YcLanding>
    <KsLanding v-if="showLanding == 'ks'"></KsLanding>
    <copyksLanding v-if="showLanding == 'copyks'"></copyksLanding>
    <XfLanding v-if="showLanding == 'xf'"></XfLanding>
    <RWLading v-if="showLanding == 'rw'"></RWLading>
    <LSYJLading v-if="showLanding == 'lsyj'"></LSYJLading>
  </div>
</template>

<script>
import appConfigMixins from "@c/mixins/appConfig.mixins";
import CpLanding from "@page/landing/template/cp/home";
import YbLanding from "@page/landing/template/yb/home";
import LaLanding from "@page/landing/template/la01/home";
import WnLanding from "@page/landing/template/wn/home";
import ObLanding from "@page/landing/template/ob/home";
import YcLanding from "@page/landing/template/yc/home";
import KsLanding from "@page/landing/template/ks/home";
import copyksLanding from "@page/landing/template/copyks/home";
import XfLanding from "@page/landing/template/xf/home";
import PJLading from "@page/landing/template/pj01/home";
import ConciseLading from "@page/landing/template/concise/home";
import RWLading from "@page/landing/template/rw/home";
import LSYJLading from "@page/landing/template/lsyj/home";
import KYLanding from "@page/landing/template/ky/home";
export default {
  mixins: [appConfigMixins],
  components: {
    CpLanding,
    YbLanding,
    LaLanding,
    WnLanding,
    ObLanding,
    YcLanding,
    KsLanding,
    XfLanding,
    PJLading,
    RWLading,
    copyksLanding,
    ConciseLading,
    LSYJLading,
    KYLanding
  },
  name: "App",
  computed: {
    showLanding() {
      return ((this.$store.state.appConfig.landing_config || {}).configValue || {}).template;
    },
  }
};
</script>

<style lang="scss">
</style>
