<template>
  <div class="content">
    <Header></Header>
    <Banner :bannerImg="$store.getters.getUrl('yb_banner')"></Banner>
    <LogoNameImg :logoNameImg="$store.getters.getUrl('yb_logoNameImg')"></LogoNameImg>
    <DownBtn v-if="!lineTop"></DownBtn>
    <DownBottomImg v-if="!lineTop"></DownBottomImg>
    <Xianlu></Xianlu>
    <DownBtn v-if="lineTop"></DownBtn>
    <DownBottomImg v-if="lineTop"></DownBottomImg>
    <KindList></KindList>
    <HotList :popularImg="$store.getters.getUrl('yb_popularImg')"></HotList>
    <Bottom :bottomImg="$store.getters.getUrl('yb_bottomImg')"></Bottom>
  </div>
</template>

<script>
import Banner from "@page/landing/components/banner.vue";
import LogoNameImg from "@page/landing/components/logoNameImg.vue";
import Bottom from "@page/landing/components/bottom.vue";
import HotList from "@page/landing/components/hotList.vue";


import Header from "@page/landing/template/yb/components/header.vue";
import DownBtn from "@page/landing/template/yb/components/downbtn.vue";
import DownBottomImg from "@page/landing/template/yb/components/downBottomImg.vue";
import Xianlu from "@page/landing/template/yb/components/xianlu.vue";
import KindList from "@page/landing/template/yb/components/kindList.vue";

export default {
  components: {
    Header,
    Banner,
    LogoNameImg,
    DownBtn,
    DownBottomImg,
    Xianlu,
    KindList,
    HotList,
    Bottom
  },
  computed: {
    lineTop() {
      return ((this.$store.state.appConfig.landing_config || {}).configValue || {}).lineTop;
    },
  }
};
</script>

<style lang="scss" scoped>
.content{
  background: url('../../../../assets/base64Img/bj.jpg') center center;
  background-size: 100% 100%;
}
</style>
